<template>
  <v-row>
    <v-col class="mt-10" cols="12"><ExportXray /></v-col>
  </v-row>
</template>
<script>
import ExportXray from "@/views/ExportExcel/ExportForm4";
export default {
    components: { ExportXray}
};
</script>